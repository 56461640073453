import { styled } from "@linaria/react";

const Container = styled.div`
  padding: 16px;

  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    margin: 0;
  }
`;

const S = {
  Container
};

export default S;
