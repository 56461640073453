export const personReportSectionTitles = {
  PERSONAL_DETAILS: "Overview",
  SCREENING: "Screening",
  IMAGE_GALLERY: "Image gallery",
  PROFESSIONAL_OVERVIEW: "Professional overview",
  BUSINESS_ASSOCIATES: "Business associates",
  LOCATIONS: "Locations",
  ASSOCIATED_INDUSTRIES: "Associated industries",
  WEB_AND_MEDIA: "Web and media",
  AWARDS_EDUCATION: "Awards and education",
  INSIGHT_CENTRE: "Insight centre",
  LIKELY: "Likely",
  POSSIBLE: "Theoretically possible but unlikely",
  DISREGARDED: "Discarded web and media",
  NOT_PROCESSED: "Not processed",
  ASSOCIATED_ENTITIES: "Associated entities",
  RISK_EVENTS: "Risk events",
  NOTES: "Your notes",
  XAPIENDISCLAIMER: "Disclaimer",
  PDXDISCLAIMER: "Confidentiality statement and disclaimer",
  DISCLAIMER: "Confidentiality statement and disclaimer",
  INSIGHTS: "Insights"
};

export const orgReportSectionTitles = {
  ORGANISATION_DETAILS: "Overview",
  GROUP_OR_PARENT_COMPANY: "Group or parent company",
  SCREENING: "Screening",
  RISK_EVENTS: "Risk events",
  DIRECTORS: "Directors",
  SIGNIFICANT_PEOPLE: "Significant people",
  WEB_AND_MEDIA: "Web and media",
  INSIGHT_CENTRE: "Insight centre",
  RELATED_ORGS: "Related organisations",
  DISREGARDED: "Discarded web and media",
  NOT_PROCESSED: "Not processed",
  NOTES: "Your notes",
  XAPIENDISCLAIMER: "Disclaimer",
  PDXDISCLAIMER: "Confidentiality statement and disclaimer",
  DISCLAIMER: "Confidentiality statement and disclaimer",
  UBODIAGRAM: "Beneficial ownership structure",
  INSIGHTS: "Insights"
};

export const legalEntityReportSectionTitles = {
  ORGANISATION_DETAILS: "Overview",
  GROUP_OR_PARENT_COMPANY: "Group or parent company",
  SCREENING: "Screening",
  RISK_EVENTS: "Risk events",
  DIRECTORS: "Directors",
  SIGNIFICANT_PEOPLE: "Significant people",
  WEB_AND_MEDIA: "Web and media",
  INSIGHT_CENTRE: "Insight centre",
  RELATED_ORGS: "Related organisations",
  DISREGARDED: "Discarded web and media",
  NOT_PROCESSED: "Not processed",
  NOTES: "Your notes",
  XAPIENDISCLAIMER: "Disclaimer",
  PDXDISCLAIMER: "Confidentiality statement and disclaimer",
  DISCLAIMER: "Confidentiality statement and disclaimer",
  UBODIAGRAM: "Beneficial ownership structure",
  RELATED_PARTIES_RESEARCH: "Related parties research"
};
