import { styled } from "@linaria/react";
import { ReactComponent as BuildingSvg } from "img/building-skinny.svg";
import { ReactComponent as PersonSvg } from "img/person.svg";
import { grey, standardColors } from "styles/colors";

const IconContainer = styled.div`
  position: relative;
  background: ${grey.mid};
  color: ${standardColors.white};
  width: 48px;
  min-width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PersonIcon = styled(PersonSvg)`
  height: 24px;
`;

const OrganisationIcon = styled(BuildingSvg)`
  height: 24px;
`;

const LoadingImageContainer = styled.div`
  position: absolute;
  top: -10px;
  left: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);

  img {
    width: 100%;
    height: 100%;
  }
`;

const S = {
  IconContainer,
  PersonIcon,
  OrganisationIcon,
  LoadingImageContainer
};

export default S;
