import React, { useState, useRef, useEffect, useCallback } from "react";

import Masonry from "react-masonry-css";

import {
  PRINTABLE_STATE_TYPES,
  usePrintableReportState
} from "util/hooks/usePrintableState";
import { Section } from "pages/report/Section";
import DeprecatedEntityCard from "components/molecules/DeprecatedEntityCard";
import SignificantPeopleCardContent from "components/molecules/DeprecatedEntityCard/SignificantPeopleCardContent";
import SectionFooter from "components/atoms/SectionFooter";
import BusinessAssociatesCard from "components/molecules/BusinessAssociatesCard";

import S, { classNameOverrides } from "./styles";
import { PROFILE_CARD_TYPE } from "./utils";

const breakpointColumnsObj = {
  default: 3,
  900: 2,
  650: 1
};

const Profiles = React.forwardRef((props, ref) => {
  const {
    profileData,
    isExpanded,
    setExpanded,
    title,
    nameOnlySearch,
    profileType,
    notShowingAllDirectors,
    isReportRegenerationOpen
  } = props;

  const resultsSectionRef = useRef(null);
  const significantPeopleRef = useRef(null);
  const [isResultsExpanded, setIsResultsExpanded] = usePrintableReportState(
    "profile-cards-expanded-fully",
    false,
    PRINTABLE_STATE_TYPES.sectionExpand
  );
  const [tooltipCloseOverride, setTooltipCloseOverride] = useState(false);
  const [isShowingExpandButton, setIsShowingExpandButton] = useState();

  const toggleTooltipOverride = useCallback(() => {
    setTooltipCloseOverride(true);
  }, [setTooltipCloseOverride]);

  // Scroll event listener for toggling the closing of active tooltips.
  useEffect(() => {
    if (isResultsExpanded) {
      window.addEventListener("scroll", toggleTooltipOverride);
    } else {
      window.removeEventListener("scroll", toggleTooltipOverride);
    }
    return () => window.removeEventListener("scroll", toggleTooltipOverride);
  }, [isResultsExpanded, toggleTooltipOverride]);

  const renderSignificantPeople = dataToRender => {
    if (!dataToRender?.length) {
      return null;
    }

    return (
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className={classNameOverrides.masonryGrid}
        columnClassName={classNameOverrides.masonryColumn}
      >
        {dataToRender.map((dataItem, key) => (
          <DeprecatedEntityCard
            {...dataItem}
            key={`significantPeople_card_${props.name}_${key}`}
            cardIndex={key}
            nameOnlySearch={nameOnlySearch}
            tooltipCloseOverride={tooltipCloseOverride}
            setTooltipCloseOverride={setTooltipCloseOverride}
            shouldCardBeUniformHeight={dataToRender.length <= 6}
            customContent={<SignificantPeopleCardContent {...dataItem} />}
            showCardImmediately={false}
            imageSource={dataItem.image}
          />
        ))}
      </Masonry>
    );
  };

  const renderBusinessAssociates = dataToRender => {
    return (
      <S.FlowContainer>
        {dataToRender.map((dataItem, key) => (
          <BusinessAssociatesCard
            cardData={dataItem}
            key={`businessAssociate_card_${props.name}_${key}`}
            cardIndex={key}
            nameOnlySearch={nameOnlySearch}
          />
        ))}
      </S.FlowContainer>
    );
  };

  const renderContent = dataToRender => {
    return (
      <div ref={ref}>
        {profileType === PROFILE_CARD_TYPE.significantPeople
          ? renderSignificantPeople(dataToRender)
          : renderBusinessAssociates(dataToRender)}
      </div>
    );
  };

  const onToggleExpandResultsSection = () => {
    setIsResultsExpanded(prevState => !prevState);
    if (isResultsExpanded) {
      // Then we must be collapsing the results so ensure
      // the results section remains in view.

      const rect = resultsSectionRef.current.getBoundingClientRect();

      // If results section's top is now hidden i.e. above the viewport then:
      if (rect.top <= 0) {
        // Bring the section into view
        significantPeopleRef.current.scrollIntoView();
      }
    }
  };

  const renderSectionBanner = () => {
    let banner;

    if (profileType === PROFILE_CARD_TYPE.significantPeople) {
      if (profileData?.length) {
        banner = (
          <S.SectionBannerInfo>
            {notShowingAllDirectors
              ? "We have identified more than 30 directorships - only showing those with active roles in the business."
              : "Individuals who hold directorships or leadership roles at the organisation or its subsidiaries."}
          </S.SectionBannerInfo>
        );
      } else {
        banner = (
          <S.SectionBannerInfo>No significant people found</S.SectionBannerInfo>
        );
      }
    } else {
      banner = (
        <S.SectionBannerInfo>
          {!profileData?.length && (
            <S.SectionBannerInfo>
              No business associates found
            </S.SectionBannerInfo>
          )}
        </S.SectionBannerInfo>
      );
    }

    return banner;
  };

  return (
    <div ref={significantPeopleRef}>
      <Section
        ref={ref}
        isExpanded={isExpanded}
        setExpanded={setExpanded}
        defaultExpanded
        title={title}
      >
        <S.ResultsSection
          id="results-section" // Used for setting overflow to hidden during inspector resize (for Safari)
          ref={resultsSectionRef}
          isResultsExpanded={isResultsExpanded}
          onScroll={() => {
            setTooltipCloseOverride(true);
          }}
        >
          <S.SectionBanner>
            <S.InnerSectionBannerContent>
              {renderSectionBanner()}
              <S.SectionCount>{profileData?.length ?? 0}</S.SectionCount>
            </S.InnerSectionBannerContent>
          </S.SectionBanner>
          {renderContent(profileData)}
        </S.ResultsSection>
        {!isShowingExpandButton && <SectionFooter />}
        <S.CustomStickyExpandButton
          isReportRegenerationOpen={isReportRegenerationOpen}
          isResultsExpanded={isResultsExpanded}
          onToggleExpandResultsSection={onToggleExpandResultsSection}
          resultsSectionRef={resultsSectionRef}
          shouldShowButtonCallback={setIsShowingExpandButton}
        />
      </Section>
    </div>
  );
});

export default Profiles;
