import { type EntityList, InfoListType, TagList } from "api/entities/types";

export const countMatchingTags = (
  entityList: EntityList,
  regex: RegExp
): number => {
  return entityList.reduce((count, entity) => {
    const tagsList = entity.lists.find(
      list => list.type === InfoListType.tags
    ) as TagList;

    if (tagsList) {
      const matchingTagsCount = tagsList.items.filter(item =>
        regex.test(item.value)
      ).length;
      return count + matchingTagsCount;
    }

    return count;
  }, 0);
};
