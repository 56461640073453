import { styled } from "@linaria/react";

import { grey, standardColors } from "styles/colors";
import { fontSize } from "styles/fonts";

const Container = styled.div<{ onClick?: () => void; isActive?: boolean }>`
  cursor: ${({ onClick }) => (onClick ? "pointer" : "auto")};
  border: 1px solid ${grey.dark};
  border-radius: 100px;
  padding: 2px 8px;
  display: flex;
  color: ${({ isActive }) => (isActive ? standardColors.white : grey.dark)};

  background: ${({ isActive }) =>
    isActive ? grey.dark : standardColors.white};

  font-size: ${fontSize.xs};
`;

const Content = styled.div``;

const RightAddon = styled.div`
  border-left: 1px solid ${grey.rule};
  margin-left: 6px;
  padding-left: 6px;
`;

const S = {
  Container,
  Content,
  RightAddon
};

export default S;
