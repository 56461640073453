import { type EntityList } from "api/entities/types";

import { countMatchingTags } from "./countMatchingTags";

export const getEntityFiltersFromLists = (entityLists: EntityList[]) => {
  const uboCount = entityLists.reduce(
    (acc, entityList) => acc + countMatchingTags(entityList, /ubo/i),
    0
  );

  const shareholderCount = entityLists.reduce(
    (acc, entityList) => acc + countMatchingTags(entityList, /shareholder/i),
    0
  );

  const directorCount = entityLists.reduce(
    (acc, entityList) => acc + countMatchingTags(entityList, /director/i),
    0
  );

  return [
    {
      label: "Ultimate Beneficial Owner",
      count: uboCount,
      filterBy: /ubo/i
    },
    {
      label: "Shareholder",
      count: shareholderCount,
      filterBy: /shareholder/i
    },
    {
      label: "Director",
      count: directorCount,
      filterBy: /director/i
    }
  ];
};
