// @ts-nocheck
import React, { useEffect, useState } from "react";

import { isPDX } from "static-config";

import { ButtonKind } from "components/atoms/Button/types";
import { emboldenKeyWords } from "util/emboldenKeyWords";
import RiskPill from "components/atoms/RiskPill";
import { specialTier2Risks } from "util/specialTier2Risks";
import { ImageSizeToUse } from "util/ImageSizeToUse";
import { InformationSource, RiskSnippet } from "api/report/report-types";
import { green } from "styles/colors";
import {
  renderSourceDate,
  renderSourceIcon
} from "components/molecules/SourceList/utils";

import S, { classNameOverrides } from "./styles";

const CATEGORY_MAX_DEFAULT_SHOWN = 3;

type Category = string | { value: string; label: string; hierarchy?: string[] };

interface RiskCategory {
  id?: string;
  category: Category;
  snippets: RiskSnippet[];
}

interface Props {
  source: InformationSource & { riskCategories?: RiskCategory[] };
  selectedFilter?: string;
}

// Note: riskCategories category field, will now either be a simple string or an object containing value and label fields
const SourceCard = ({ source, selectedFilter }: Props) => {
  const [hasImageErrored, setHasImageErrored] = useState<boolean | undefined>();
  const [isCategoriesExpanded, setIsCategoriesExpanded] = useState<
    boolean | undefined
  >();
  const [selectedCategory, setSelectedCategory] = useState<
    RiskCategory | undefined
  >();
  const [sortedRiskCategories, setSortedRiskCategories] = useState<
    RiskCategory[] | undefined
  >(source?.riskCategories);

  // Propagate changes to risk categories i.e. when a risk is killed, we want the risk to disappear
  useEffect(() => {
    setSortedRiskCategories(source?.riskCategories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source?.riskCategories?.length]);

  useEffect(() => {
    // When we deselect the top level filter, we want to deselect all corresponding source filters
    if (!selectedFilter) {
      setSelectedCategory(undefined);
    } else {
      // Find match
      const riskCategories = source?.riskCategories ?? [];
      const categoryIndex = riskCategories.findIndex(cat => {
        const category =
          typeof cat.category === "string" ? cat.category : cat.category?.value;
        return category.toLowerCase() === selectedFilter?.toLowerCase();
      });

      if (categoryIndex > -1) {
        setSortedRiskCategories([
          riskCategories[categoryIndex],
          ...riskCategories.slice(0, categoryIndex),
          ...riskCategories.slice(categoryIndex + 1)
        ]);
        setSelectedCategory(riskCategories[categoryIndex]);
      } else {
        setSelectedCategory(undefined);
      }
    }
  }, [selectedFilter, source]);

  const renderRiskCategoryPills = () => {
    const riskCategories = sortedRiskCategories ?? [];

    const riskCategoryElements = riskCategories.map(cat => {
      const category =
        typeof cat.category === "string" ? cat.category : cat.category?.value;
      const categoryLabel =
        typeof cat.category === "string" ? cat.category : cat.category?.label;
      const selectedCategoryValue =
        typeof selectedCategory?.category === "string"
          ? selectedCategory.category
          : selectedCategory?.category?.value;

      return (
        <RiskPill
          key={category}
          alwaysShowKillButton
          isKillable={false}
          label={categoryLabel}
          isActive={selectedCategoryValue === category}
          ariaLabel={`Source filter: ${category}`}
          ariaChecked={selectedCategoryValue === category}
          sourceId={source.id!}
          riskHierarchies={
            typeof cat.category === "object" && cat.category.hierarchy
              ? [cat.category.hierarchy]
              : undefined
          }
          onClick={e => {
            e.stopPropagation();
            if (selectedCategoryValue === category) {
              setSelectedCategory(undefined);
            } else {
              setSelectedCategory(cat);
            }
          }}
          // eslint-disable-next-line max-len
          customModalText="It will no longer be marked as a risk in this article, but could still be identified as risk in other content. To remove it for all content, use the controls above."
        />
      );
    });

    return (
      <>
        {riskCategoryElements.slice(
          0,
          isCategoriesExpanded
            ? riskCategoryElements.length
            : CATEGORY_MAX_DEFAULT_SHOWN
        )}
        {riskCategoryElements.length > CATEGORY_MAX_DEFAULT_SHOWN ? (
          <S.RiskCountButton
            onClick={() => {
              setIsCategoriesExpanded(prevState => !prevState);
            }}
            kind={ButtonKind.secondary}
          >
            {isCategoriesExpanded
              ? "Show less"
              : `+${riskCategoryElements.length - CATEGORY_MAX_DEFAULT_SHOWN}`}
          </S.RiskCountButton>
        ) : null}
      </>
    );
  };

  return (
    <S.CardContainer>
      <S.CardInnerContainer>
        <S.CardHeader>
          <div>
            <S.SourceTitle>
              {source?.title ? (
                isPDX ? (
                  // @ts-ignore TODO: convert to ts
                  <S.TruncatedSourceTitleLink href={source?.url}>
                    {source?.title}
                  </S.TruncatedSourceTitleLink>
                ) : (
                  // @ts-ignore TODO: convert to ts
                  <S.SourceTitleLink href={source?.url}>
                    {source?.title}
                  </S.SourceTitleLink>
                )
              ) : (
                <S.NoTitleHeader>No title</S.NoTitleHeader>
              )}
            </S.SourceTitle>
            {source.subheading ??
              renderSourceDate({
                sourceType: source.sourceType,
                dateAccessed: source?.dateAccessed,
                datePublished: source?.datePublished,
                className: classNameOverrides.sourceDate
              })}
          </div>
          {source?.image &&
            (!hasImageErrored ? (
              <S.SourceImage
                image={source.image}
                width="64px"
                height="64px"
                lazyLoad={false}
                onError={() => {
                  setHasImageErrored(true);
                }}
                imageSizeToUse={ImageSizeToUse.Small}
              />
            ) : null)}
        </S.CardHeader>
        {source?.description ? (
          <S.SourceDescriptionContainer
            onClick={() => setSelectedCategory(undefined)}
          >
            {!isPDX &&
              (!selectedCategory ? (
                // These stopPropagations prevent the selected filter
                // from being deselected.
                <S.SourceDescription onClick={e => e.stopPropagation()}>
                  {source?.description}
                </S.SourceDescription>
              ) : (
                <S.CardTextContainer>
                  {selectedCategory.snippets?.length ? (
                    selectedCategory.snippets.map((snippet, index) => (
                      <S.Snippet key={index} onClick={e => e.stopPropagation()}>
                        {snippet.snippet ? (
                          <>
                            ...
                            {emboldenKeyWords(
                              snippet.snippet?.trim(),
                              snippet.highlightOffsets,
                              classNameOverrides.boldenRisk
                            )}
                            ...
                          </>
                        ) : null}
                      </S.Snippet>
                    ))
                  ) : (
                    <S.NoSnippet>
                      {selectedCategory.id ===
                      specialTier2Risks.highRiskSites ? (
                        <>{source.publisher} is considered a high-risk site.</>
                      ) : (
                        "No snippet found"
                      )}
                    </S.NoSnippet>
                  )}
                </S.CardTextContainer>
              ))}
            <S.RiskCategories>{renderRiskCategoryPills()}</S.RiskCategories>
          </S.SourceDescriptionContainer>
        ) : null}
        {!isPDX ? (
          <S.CardFooter>
            <div>
              {renderSourceIcon(source.sourceType, source.publisher, green.mid)}
            </div>
            {!source?.url && !source?.isAvailableOnline ? (
              <S.SourceUrlPlaceholder>
                {source?.notAvailableMessage ?? "Not available online"}
              </S.SourceUrlPlaceholder>
            ) : (
              <S.SourceLink
                href={source?.url ?? ""}
                popoverClassName={classNameOverrides.sourceLink}
                className={undefined}
                style={undefined}
                ariaLabel={undefined}
                disabled={undefined}
                popoverPortalAnchorElement={undefined}
              >
                {source?.url}
              </S.SourceLink>
            )}
          </S.CardFooter>
        ) : null}
      </S.CardInnerContainer>
    </S.CardContainer>
  );
};

export default SourceCard;
