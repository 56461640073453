import React, { type FC } from "react";

import { type RoleItem } from "api/entities";
import { type InformationSource } from "api/report/report-types";

import WithInspector from "components/organisms/WithInspector";

import S from "./styles";

interface Props {
  items: RoleItem[];
  sources: InformationSource[];
}

const EntityRoles: FC<Props> = ({ items, sources }) => {
  if (items.length > 0) {
    const {
      title: currentRoleTitle,
      duration,
      dateFrom: currentRoleDateFrom
    } = items[0];

    const formatDate = (date?: Date) => {
      return date?.toLocaleString("default", {
        month: "short",
        year: "numeric"
      });
    };

    return (
      <WithInspector
        sources={sources}
        topSectionElement={
          <S.RoleItemList>
            {items.map(({ title, dateFrom, dateTo }) => (
              <S.RoleItem>
                <S.CurrentRole>{title}</S.CurrentRole>
                <S.OtherRoles>
                  {formatDate(dateFrom)}
                  {dateTo && dateFrom && " - "}
                  {formatDate(dateTo)}
                </S.OtherRoles>
              </S.RoleItem>
            ))}
          </S.RoleItemList>
        }
      >
        <S.Roles>
          <S.CurrentRole>{currentRoleTitle}</S.CurrentRole>
          <S.OtherRoles>
            {duration && `(${duration} years)`}{" "}
            {items.length > 1 && `+${items.length - 1} role`}
            {items.length > 2 && "s"}{" "}
            {currentRoleDateFrom && `since ${formatDate(currentRoleDateFrom)}`}
          </S.OtherRoles>
        </S.Roles>
      </WithInspector>
    );
  }

  return null;
};

export default EntityRoles;
