// import config from "config";
import { stage } from "services/stage";
import { ApiRequestOptions } from "../portal/core/ApiRequestOptions";

// temp hack to get around the fact we have one OpenAPI spec but it's spread accross two APIs
export const customBaseUrl = (options: ApiRequestOptions) => {
  const envUrl = `${stage}.xapien.com`;
  const portalUrl = `https://api.portal.${envUrl}`;
  const enquiryReportServiceUrl = `https://api.enquiry-report-service.${envUrl}`;
  const identityUrl = `https://api.identity.${envUrl}`;
  const hubUrl = `https://tenant-api.identity.${envUrl}`;

  const { url, method } = options;
  if (url && url.startsWith("/tenants")) {
    return hubUrl;
  }
  if (url && url.startsWith("/v1/enquiry")) {
    return enquiryReportServiceUrl;
  }
  if (
    url &&
    (url === "/enquiries/start-enquiry" ||
      (url === "/reports/{reportId}" && method === "DELETE") ||
      url === "/reports/pdf-metadata" ||
      url === "/reports/pdf-metadata/{requestId}")
  ) {
    return portalUrl;
  }
  return identityUrl;
};
