import React, { useState } from "react";

import { ENTITY_TYPE } from "pages/define/utils";
import DeprecatedEntityCard from "components/molecules/DeprecatedEntityCard";
import SanctionedEntityCardContent from "themes/projectdx/ThemedScreening/SanctionedEntityCardContent";
import { getEntityTypeImage } from "themes/projectdx/ThemedScreening/util";
import ReactTooltip from "components/atoms/ReactTooltip";
import { grey } from "styles/colors";

import S, { classNameOverrides } from "./styles";

const RelatedEntityCard = ({
  entity,
  entitiesBackgroundColorOverride,
  entitiesImageSize,
  hideNewReportButton,
  disableNewReportButton,
  renderFlagHierarchyPills,
  filterBucketType,
  reportSubjectType,
  reportSubjectName,
  onViewAssociateProfile,
  associatedEntityProfiles
}) => {
  const [isComingSoonTooltipOpen, setIsComingSoonTooltipOpen] = useState(false);
  const entityType = entity.entityType?.toLowerCase();

  return (
    <ReactTooltip
      open={isComingSoonTooltipOpen}
      tooltip={
        <S.TooltipContent>
          <S.TooltipHeader>Coming soon</S.TooltipHeader>
          <S.TooltipBody>
            Future updates will allow you to view the full profile information
            for related organisations and people.
          </S.TooltipBody>
        </S.TooltipContent>
      }
    >
      <DeprecatedEntityCard
        isHoverable={
          reportSubjectType === ENTITY_TYPE.Person &&
          associatedEntityProfiles?.[entity.profileId]
        }
        onClick={() => {
          if (
            reportSubjectType === ENTITY_TYPE.Person &&
            associatedEntityProfiles?.[entity.profileId]
          ) {
            onViewAssociateProfile(entity.profileId);
          } else {
            setIsComingSoonTooltipOpen(prevState => !prevState);
          }
        }}
        onMouseLeave={() => setIsComingSoonTooltipOpen(false)}
        key={entity.entityName}
        customContent={
          <SanctionedEntityCardContent
            entity={entity}
            isCloseAssociate
            renderFlagHierarchyPills={renderFlagHierarchyPills}
            filterBucketType={filterBucketType}
            entityType={entityType}
            reportSubjectType={reportSubjectType}
          />
        }
        imageComponent={
          <S.EntityImage
            filterBucketType={filterBucketType}
            size={entitiesImageSize}
          >
            {getEntityTypeImage(
              entityType,
              entitiesImageSize === "small"
                ? classNameOverrides.entityIcon
                : null
            )}
          </S.EntityImage>
        }
        disableInspectorInteraction
        minHeight={0}
        cardStyles={
          reportSubjectType === ENTITY_TYPE.Person
            ? classNameOverrides.rcaCardStyles
            : classNameOverrides.cardStyles
        }
        cardBackgroundColorOverride={
          entitiesBackgroundColorOverride ?? grey.lighterPanel
        }
        hideNewReportButton={hideNewReportButton}
        disableNewReportButton={disableNewReportButton}
        nameToSendToNewReport={entity.entityName}
        contextToUse={reportSubjectName}
        name={entity.entityName}
        reportSubjectType={reportSubjectType}
        entityType={entityType}
      />
    </ReactTooltip>
  );
};

export default RelatedEntityCard;
