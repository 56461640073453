import { SubjectType, ContextType } from "api/enquiry/types";
import {
  FilterList,
  type Entity,
  type EntityList,
  type EntityListDetails
} from "api/entities/types";

export interface EntityListState {
  activeFilters?: RegExp[];
  filters?: FilterList;
  isExpanded?: boolean;
  details?: EntityListDetails;
  entityList?: EntityList;
  commissionedEntityList?: EntityList;
}

export enum EntityListActions {
  updateEntityList = "updateEntityList",
  updateCommissionedEntityList = "updateCommissionedEntityList",
  updateCommissionedEntity = "updateCommissionedEntity",
  updateFilters = "updateFilters",
  updateDetails = "updateDetails",
  updateIsExpanded = "updateIsExpanded",
  updateActiveFilter = "updateActiveFilter"
}

export interface NewReportProps {
  subjectType: SubjectType;
  label: string;
  contexts: { type: ContextType; value: string }[];
  successCallback: () => void;
  failureCallback: () => void;
}

export interface UpdateActiveFilterAction {
  type: EntityListActions.updateActiveFilter;
  activeFilter: RegExp;
}

export interface UpdateIsExpandedAction {
  type: EntityListActions.updateIsExpanded;
  isExpanded: boolean;
}

export interface UpdateEntityDetailsAction {
  type: EntityListActions.updateDetails;
  details: EntityListDetails;
}

export interface UpdateFiltersAction {
  type: EntityListActions.updateFilters;
  filters: FilterList;
}

export interface UpdateEntityListAction {
  type: EntityListActions.updateEntityList;
  entityList: EntityList;
}

export interface UpdateCommissionedEntityListAction {
  type: EntityListActions.updateCommissionedEntityList;
  commissionedEntityList: EntityList;
}

export interface UpdateCommissionedEntityAction {
  type: EntityListActions.updateCommissionedEntity;
  entity: Entity;
}

export type EntityListAction =
  | UpdateActiveFilterAction
  | UpdateEntityDetailsAction
  | UpdateEntityListAction
  | UpdateCommissionedEntityListAction
  | UpdateFiltersAction
  | UpdateIsExpandedAction
  | UpdateCommissionedEntityAction;
