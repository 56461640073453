import { styled } from "@linaria/react";
import { grey, blue } from "styles/colors";

const Summary = styled.p<{ showFullSummary: boolean }>`
  transition: all 1s ease;
  display: -webkit-box;
  max-height: ${({ showFullSummary }) =>
    showFullSummary ? "1000px" : "105px"};
  -webkit-line-clamp: ${({ showFullSummary }) => (showFullSummary ? 50 : 5)};
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 0;
  color: ${grey.mid};
`;

const ShowFullSummaryButton = styled.button`
  all: unset;
  outline: none;
  color: ${blue.icon};
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

const SummaryFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

const S = {
  Summary,
  SummaryFooter,
  ShowFullSummaryButton
};

export default S;
