import React, { type FC } from "react";

import { type EntityRiskFlags } from "api/entities";

import S from "./styles";

interface Props {
  riskFlags?: EntityRiskFlags;
}

const riskIcons = [
  {
    flag: "sanctions",
    icon: <S.SanctionsIcon />
  },
  {
    flag: "watchlists",
    icon: <S.WatchlistsIcon />
  },
  {
    flag: "peps",
    icon: <S.PepsIcon />
  },
  {
    flag: "rca",
    icon: <S.RCAIcon />
  },
  {
    flag: "specialInterest",
    icon: <S.SpecialInterestIcon />
  }
];

const EntityRiskIcons: FC<Props> = ({ riskFlags }) => {
  return (
    <S.RiskIcons>
      {riskIcons.map(({ flag, icon }) => (
        <S.RiskIcon isActive={!!(riskFlags && flag in riskFlags)}>
          {icon}
        </S.RiskIcon>
      ))}
    </S.RiskIcons>
  );
};

export default EntityRiskIcons;
