import { grey, standardColors, green, red } from "styles/colors";
import { EntityStatus } from "api/entities";
import {
  Eye as EyeIcon,
  Icon,
  RotateCw as ReloadIcon,
  AlertCircle as FailureIcon
} from "react-feather";

interface EntityCardVariant {
  titleColor: string;
  containerBorderColor: string;
  containerBorderWidth: string;
  viewReportButtonText: string;
  viewReportButtonDisabled: boolean;
  viewReportButtonIcon: Icon;
}

export const statusVariants: Record<EntityStatus, EntityCardVariant> = {
  [EntityStatus.done]: {
    titleColor: grey.dark,
    containerBorderWidth: "1px",
    containerBorderColor: standardColors.black,
    viewReportButtonText: "View report",
    viewReportButtonDisabled: false,
    viewReportButtonIcon: EyeIcon
  },
  [EntityStatus.inProgress]: {
    titleColor: grey.disabled,
    containerBorderWidth: "2px",
    containerBorderColor: green.xapienGreen,
    viewReportButtonText: "Running...",
    viewReportButtonDisabled: true,
    viewReportButtonIcon: ReloadIcon
  },
  [EntityStatus.queued]: {
    titleColor: grey.disabled,
    containerBorderWidth: "2px",
    containerBorderColor: green.xapienGreen,
    viewReportButtonText: "Running...",
    viewReportButtonDisabled: true,
    viewReportButtonIcon: ReloadIcon
  },
  [EntityStatus.failed]: {
    titleColor: grey.disabled,
    containerBorderWidth: "2px",
    containerBorderColor: red.directRiskFill,
    viewReportButtonText: "Failed",
    viewReportButtonDisabled: true,
    viewReportButtonIcon: FailureIcon
  }
};
