import React from "react";

import useEntityList from "util/hooks/useEntityList";
import Popover from "components/atoms/Popover";
import S from "./styles";

const EntityListDetails = () => {
  const {
    state: { details },
    filteredEntityList,
    filteredCommissionedEntityList
  } = useEntityList();

  const totalEntityListCount =
    (filteredEntityList?.length ?? 0) +
    (filteredCommissionedEntityList?.length ?? 0);

  return (
    <S.Container>
      <S.Heading>{totalEntityListCount}</S.Heading>
      {details && (
        <S.Content>
          <span>
            {details?.label} <strong>{details?.value}</strong>
          </span>
          <S.Link>
            <Popover
              arrowColor="white"
              hideArrow
              alignment="bottom-end"
              maxWidth="360px"
              content={details?.helpText}
            >
              <S.InfoIcon /> Learn more
            </Popover>
          </S.Link>
        </S.Content>
      )}
    </S.Container>
  );
};

export default EntityListDetails;
