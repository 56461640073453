import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import { InformationSource } from "api/report/report-types";
import {
  type RoleItem,
  InfoListType,
  EntityType,
  RoleList,
  LocationList,
  TagList
} from "api/entities/types";
import { type model_RelatedParty } from "api/related-parties";

export const transformPersonEntity = (entity: model_RelatedParty) => {
  const {
    informationSources,
    name,
    relatedPartyId: id,
    relatedCompany,
    relationships,
    locations: locationsResponse,
    roles: rolesResponse,
    onwardResearchRecommended
  } = entity;

  const roles: RoleList = {
    type: InfoListType.roles,
    items:
      rolesResponse?.map(({ roleTitle, dateFrom, dateTo }): RoleItem => {
        const dateFromDate =
          dateFrom?.day && dateFrom?.month && dateFrom?.year
            ? new Date(dateFrom.year, dateFrom.month, dateFrom.day)
            : undefined;
        const dateToDate =
          dateTo?.day && dateTo?.month && dateTo?.year
            ? new Date(dateTo.year, dateTo.month, dateTo.day)
            : undefined;

        return {
          id: uuidv4(),
          title: roleTitle,
          company: relatedCompany,
          duration:
            dateFromDate && dateToDate
              ? dayjs(dateToDate).diff(dayjs(dateFromDate), "year")
              : undefined,
          dateFrom: dateFromDate,
          dateTo: dateToDate
        };
      }) ?? []
  };

  const locations: LocationList = {
    type: InfoListType.locations,
    items:
      locationsResponse?.map(({ city, country }) => ({
        id: uuidv4(),
        value: [city, country?.countryName].filter(Boolean).join(", ")
      })) ?? []
  };

  const tags: TagList = {
    type: InfoListType.tags,
    items:
      relationships?.map(relationship => ({
        id: uuidv4(),
        value: relationship
      })) ?? []
  };

  const sources = informationSources
    ? (informationSources as unknown as InformationSource[])
    : [];

  return {
    id,
    isRecommended: !!onwardResearchRecommended,
    entityType: EntityType.person,
    title: name,
    sources,
    lists: [roles, locations, tags]
  };
};
