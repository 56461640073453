import React, { type FC } from "react";

import S from "./styles";

interface Props {
  isRecommended: boolean;
}

const EntityStatusIcon: FC<Props> = ({ isRecommended }) => {
  if (isRecommended) {
    return <S.Icon>★</S.Icon>;
  }
  return null;
};

export default EntityStatusIcon;
