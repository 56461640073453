import { styled } from "@linaria/react";
import { grey } from "styles/colors";

import { ReactComponent as ChevronDownSvg } from "img/icons/chevron-down.svg";
import { ReactComponent as ChevronUpSvg } from "img/icons/chevron-up.svg";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${grey.panel};
  padding: 16px 0;
`;

const ChevronDownIcon = styled(ChevronDownSvg)`
  margin: 8px 4px 0;
`;

const ChevronUpIcon = styled(ChevronUpSvg)`
  margin: 6px 4px 0;
`;
const S = { Container, ChevronDownIcon, ChevronUpIcon };

export default S;
