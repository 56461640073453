import { FetchResult } from "api/types";
import { apm } from "@elastic/apm-rum";
import { EnquiryService } from "api/related-parties";
import { hasErrorCode } from "util/hasErrorCode";
import { type Entity, type FilterList, type EntityList } from "./types";
import {
  transformEntity,
  transformCommissionedEntity,
  getEntityFiltersFromLists
} from "./utils";

export {
  EntityType,
  InfoListType,
  EntityStatus,
  type FilterList,
  type InfoList,
  type Entity,
  type EntityList,
  type RoleItem,
  type LocationItem,
  type TagItem,
  type DateItem,
  type EntityRiskFlags
} from "./types";

export default class Entities {
  async createCommissionedParty({
    enquiryId,
    relatedPartyId
  }: {
    enquiryId: string;
    relatedPartyId: string;
  }): Promise<FetchResult<Entity>> {
    try {
      const response = await EnquiryService.putV1EnquiryCommissionResearch({
        enquiryId,
        relatedPartyId
      });

      if (!response) {
        throw new Error("No response from put commissioned party");
      }

      const entity = transformCommissionedEntity({
        ...response
      });

      return {
        status: true,
        response: entity
      };
    } catch (e: unknown) {
      apm.captureError(e as Error);

      console.error(e);

      if (hasErrorCode(e)) {
        return { status: false, message: e.body.error };
      }

      return { status: false, message: "Failed to commissioned a party" };
    }
  }

  async getCommissionedParty({
    enquiryId,
    relatedPartyId
  }: {
    enquiryId: string;
    relatedPartyId: string;
  }): Promise<FetchResult<Entity>> {
    try {
      const response = await EnquiryService.getV1Enquiry1({
        enquiryId,
        relatedPartyId
      });

      if (!response) {
        throw new Error("No response from fetch commissioned party");
      }

      const entity = transformCommissionedEntity({
        ...response
      });

      return {
        status: true,
        response: entity
      };
    } catch (e: unknown) {
      apm.captureError(e as Error);

      console.error(e);

      if (hasErrorCode(e)) {
        return { status: false, message: e.body.error };
      }

      return { status: false, message: "Failed to fetch commissioned party" };
    }
  }

  async getRelatedParties({
    enquiryId,
    shareToken: _shareToken
  }: {
    enquiryId: string;
    shareToken?: string | null;
  }): Promise<
    FetchResult<{
      entityList: EntityList;
      commissionedEntityList: EntityList;
      entityFilters: FilterList;
    }>
  > {
    try {
      const response = await EnquiryService.getV1Enquiry({ enquiryId });

      if (!response) {
        throw new Error("No response from related parties");
      }

      const { relatedParties, commissionedRelatedParties } = response;

      const entityList: EntityList = relatedParties.map(entity =>
        transformEntity(entity)
      );
      const commissionedEntityList: EntityList = commissionedRelatedParties.map(
        entity => transformCommissionedEntity(entity)
      );

      const entityFilters = getEntityFiltersFromLists([
        entityList,
        commissionedEntityList
      ]);

      return {
        status: true,
        response: { entityList, commissionedEntityList, entityFilters }
      };
    } catch (e: unknown) {
      apm.captureError(e as Error);

      console.error(e);

      if (hasErrorCode(e)) {
        return { status: false, message: e.body.error };
      }

      return { status: false, message: "Failed to fetch related parties data" };
    }
  }
}
