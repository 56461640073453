type ErrorResponseWithBody = {
  body: {
    error: string;
  };
};

export const hasErrorCode = (
  response: unknown
): response is ErrorResponseWithBody =>
  response !== null &&
  typeof response === "object" &&
  "body" in response &&
  response.body !== null &&
  typeof response.body === "object" &&
  "error" in response.body &&
  typeof response.body.error === "string";
