import { styled } from "@linaria/react";
import { grey, green } from "styles/colors";
import { MapPin } from "react-feather";

const Locations = styled.div`
  padding: 4px 0;
  display: flex;
  gap: 4px;
  align-items: center;
  color: ${grey.mid};
`;

const LocationIcon = styled(MapPin)`
  width: 12px;
  height: 18px;
`;

const LocationItemList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const LocationItem = styled.div`
  border: 1px solid ${green.xapienGreen};
  border-radius: 50px;
  padding: 2px 12px;
`;

const S = {
  Locations,
  LocationIcon,
  LocationItemList,
  LocationItem
};

export default S;
