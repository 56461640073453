import React from "react";

import useEntityList from "util/hooks/useEntityList";
import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";

import S from "./styles";

const EntityListToggle = () => {
  const {
    state: { isExpanded },
    updateIsExpanded
  } = useEntityList();

  const handleExpandToggle = () => updateIsExpanded(!isExpanded);

  return (
    <S.Container>
      <ButtonNew
        onClick={handleExpandToggle}
        text={isExpanded ? "Collapse" : "Expand"}
        type={ButtonType.FilledLight}
        size={ButtonSize.Medium}
        IconLeading={isExpanded ? S.ChevronUpIcon : S.ChevronDownIcon}
        IconTrailing={isExpanded ? S.ChevronUpIcon : S.ChevronDownIcon}
      />
    </S.Container>
  );
};

export default EntityListToggle;
