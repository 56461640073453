import { styled } from "@linaria/react";

const Tags = styled.div`
  padding-top: 4px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const S = {
  Tags
};

export default S;
