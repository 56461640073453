import { styled } from "@linaria/react";
import { grey } from "styles/colors";

const Dates = styled.div`
  color: ${grey.mid};
`;

const S = {
  Dates
};

export default S;
