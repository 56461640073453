import React, { FC, useState } from "react";
import UBOCountryCircles from "components/molecules/UBOCountryCircles";
import ButtonNew from "components/atoms/ButtonNew";
import NewReportModal from "components/organisms/NewReportModal";
import { ContextType, SubjectType } from "api/enquiry/types";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import type { UboReportNode } from "api/ubo-reports";
import S from "./styles";

interface Props {
  data: UboReportNode["data"];
  contexts: { type: ContextType; value: string }[];
  subjectType: SubjectType;
  isBusiness: boolean;
}

const UBOProfileCard: FC<Props> = ({
  data: {
    type,
    label,
    identifiers,
    countries,
    addresses,
    integratedPercentage
  },
  isBusiness,
  subjectType,
  contexts
}) => {
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const renderAddresses = () => {
    if (addresses.length === 0) {
      return "None Identified";
    }

    return addresses.map(address => (
      <div key={`UBOProfileCardAddress-${address}`}>{`${address}`}</div>
    ));
  };

  const renderIdentifiers = () => {
    if (identifiers.length === 0) {
      return "None Identified";
    }

    return identifiers.map(({ label: identifierLabel, value }) => (
      <div
        key={`UBOProfileCardIdentifier-${identifierLabel}-${value}`}
      >{`${identifierLabel}: ${value}`}</div>
    ));
  };

  const onCloseReportModal = () => setIsReportModalOpen(false);
  const onOpenReportModal = () => setIsReportModalOpen(true);

  return (
    <S.Container>
      <S.Header>
        <S.HeaderIcon>
          {isBusiness ? <S.OrgIcon /> : <S.PersonIcon />}
        </S.HeaderIcon>

        <S.TitleContainer>
          <S.HeaderTitle>{label}</S.HeaderTitle>
          {integratedPercentage ? (
            <S.HeaderTagline>
              {integratedPercentage}% Total Ownership
            </S.HeaderTagline>
          ) : null}
          <S.HeaderSubtitle>{type}</S.HeaderSubtitle>
        </S.TitleContainer>
      </S.Header>

      <UBOCountryCircles countries={countries} />

      <S.DataContainer>
        <S.DataTitle>Identifiers</S.DataTitle>
        <S.DataItems>{renderIdentifiers()}</S.DataItems>
      </S.DataContainer>

      <S.DataContainer>
        <S.DataTitle>Address</S.DataTitle>
        <S.DataItems>{renderAddresses()}</S.DataItems>
      </S.DataContainer>

      <ButtonNew
        type={ButtonType.OutlinedLight}
        size={ButtonSize.Medium}
        text="New report"
        onClick={onOpenReportModal}
      />

      <NewReportModal
        label={label}
        subjectType={subjectType}
        contexts={contexts}
        isOpen={isReportModalOpen}
        onClose={onCloseReportModal}
      />
    </S.Container>
  );
};

export default UBOProfileCard;
