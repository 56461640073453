import { type model_CommissionedRelatedParty } from "api/related-parties";
import { EntityStatus } from "api/entities";
import { transformEntity } from "./transformEntity";

export const transformCommissionedEntity = (
  entity: model_CommissionedRelatedParty
) => {
  const transformedEntity = transformEntity(entity);
  const getEntityStatus = (enquiryStatus: string) => {
    const statusMap: Record<string, EntityStatus> = {
      QUEUED: EntityStatus.queued,
      IN_PROGRESS: EntityStatus.inProgress,
      DONE: EntityStatus.done,
      FAILED: EntityStatus.failed
    };

    return statusMap[enquiryStatus];
  };

  const {
    enquiryExecutiveSummary: summary,
    enquiryURL: enquiryUrl,
    enquiryFlags: riskFlags,
    enquiryState
  } = entity;

  return {
    ...transformedEntity,
    summary,
    riskFlags,
    enquiryUrl,
    status: getEntityStatus(enquiryState)
  };
};
