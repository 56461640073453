import { type model_RelatedParty } from "api/related-parties";
import { transformPersonEntity } from "./transformPersonEntity";
import { transformCompanyEntity } from "./transformCompanyEntity";

export const transformEntity = (entity: model_RelatedParty) => {
  switch (entity.entityType) {
    case "Person": {
      return transformPersonEntity(entity);
    }
    case "Organisation":
    case "Company": {
      return transformCompanyEntity(entity);
    }
    default: {
      throw new Error(`Unknown entity type ${entity.entityType}`);
    }
  }
};
