import React, { type FC } from "react";

import { type LocationItem } from "api/entities";
import { type InformationSource } from "api/report/report-types";

import WithInspector from "components/organisms/WithInspector";

import S from "./styles";

interface Props {
  items: LocationItem[];
  sources: InformationSource[];
}

const EntityLocations: FC<Props> = ({ items, sources }) => {
  if (items.length > 0) {
    return (
      <WithInspector
        sources={sources}
        topSectionElement={
          <S.LocationItemList>
            {items.map(({ value }) => (
              <S.LocationItem>{value}</S.LocationItem>
            ))}
          </S.LocationItemList>
        }
      >
        <S.Locations>
          <S.LocationIcon />
          <span> {items[0].value}</span>
          <span>{items.length > 1 && `+${items.length - 1}`}</span>
        </S.Locations>
      </WithInspector>
    );
  }
  return (
    <S.Locations>
      <S.LocationIcon />
      <span>No location available</span>
    </S.Locations>
  );
};

export default EntityLocations;
