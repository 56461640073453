import { styled } from "@linaria/react";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Footer = styled.div``;

const S = {
  Container,
  Header,
  Footer
};

export default S;
