import { v4 as uuidv4 } from "uuid";
import { InformationSource } from "api/report/report-types";
import {
  InfoListType,
  LocationList,
  EntityType,
  TagList,
  DateList
} from "api/entities/types";
import { type model_RelatedParty } from "api/related-parties";

export const transformCompanyEntity = (entity: model_RelatedParty) => {
  const {
    informationSources,
    name,
    relatedPartyId: id,
    relationships,
    companyType,
    locations: locationsResponse,
    onwardResearchRecommended
  } = entity;

  const sources = informationSources
    ? (informationSources as unknown as InformationSource[])
    : [];

  const dates: DateList = {
    type: InfoListType.dates,
    items: []
  };

  const locations: LocationList = {
    type: InfoListType.locations,
    items:
      locationsResponse?.map(({ city, country }) => ({
        id: uuidv4(),
        value: [city, country?.countryName].filter(Boolean).join(", ")
      })) ?? []
  };

  const tags: TagList = {
    type: InfoListType.tags,
    items:
      relationships?.map(relationship => ({
        id: uuidv4(),
        value: relationship
      })) ?? []
  };

  return {
    id,
    isRecommended: !!onwardResearchRecommended,
    entityType: EntityType.organisation,
    title: name,
    subtitle: companyType,
    sources,
    lists: [dates, locations, tags]
  };
};
