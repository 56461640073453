import React, { type FC } from "react";

import { EntityType, EntityStatus } from "api/entities";

import loadingImage from "img/entity-loading-animation.gif";

import S from "./styles";

interface Props {
  entityType: EntityType;
  status?: EntityStatus;
}

const EntityCardAvatar: FC<Props> = ({ entityType, status }) => {
  const isLoading =
    status === EntityStatus.inProgress || status === EntityStatus.queued;

  switch (entityType) {
    case EntityType.organisation: {
      return (
        <S.IconContainer>
          <S.OrganisationIcon />
          {isLoading && (
            <S.LoadingImageContainer>
              <img src={loadingImage} alt="" />
            </S.LoadingImageContainer>
          )}
        </S.IconContainer>
      );
    }
    case EntityType.person:
    default:
      return (
        <S.IconContainer>
          <S.PersonIcon />
          {isLoading && (
            <S.LoadingImageContainer>
              <img src={loadingImage} alt="" />
            </S.LoadingImageContainer>
          )}
        </S.IconContainer>
      );
  }
};

export default EntityCardAvatar;
