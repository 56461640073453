import React from "react";

import DeprecatedEntityCard from "components/molecules/DeprecatedEntityCard";
import TruncateLength from "util/TruncateLength";
import ConfidenceMenu from "components/molecules/ConfidenceMenu";
import { AssessmentOptions } from "pages/report/AssessmentOptions";
import { formatDate } from "util/common";

import { yellow } from "styles/colors";

import S from "./styles";

const SummaryCard = ({
  orgId,
  name,
  industry,
  location,
  organisationType,
  parents,
  subsidiaryIds,
  dateOfIncorporation,
  orgStatus,
  riskList,
  image,
  onCardClick,
  onConfidenceChange,
  originalSetConfidence,
  currentSetConfidence,
  userAssessmentIds,
  userAssessment
}) => {
  const renderOrgStatus = () => {
    switch (orgStatus?.statusTag?.toUpperCase()) {
      case "ACTIVE":
        return (
          <S.StatusPill>
            <TruncateLength>{orgStatus?.label}</TruncateLength>
          </S.StatusPill>
        );
      case "INACTIVE":
        return (
          <S.StatusPill color={yellow.mud}>
            <TruncateLength>{orgStatus?.label}</TruncateLength>
          </S.StatusPill>
        );
      case "WARNING":
        return (
          <S.RiskPill>
            <TruncateLength>{orgStatus?.label}</TruncateLength>
          </S.RiskPill>
        );
      default:
        return null;
    }
  };

  const cardContent = (
    <S.CardContentContainer aria-label={`Show details modal for ${name}`}>
      <S.TopContent>
        <div>
          <S.OrgName>{name}</S.OrgName>
          <S.Industry>
            <S.DataKey>Nature of business: </S.DataKey>{" "}
            {industry ? (
              <S.IndustryValue>
                <TruncateLength>{industry}</TruncateLength>
              </S.IndustryValue>
            ) : (
              <S.NoneIdentified>None identified</S.NoneIdentified>
            )}
          </S.Industry>
          <div>{location}</div>
        </div>
        {onConfidenceChange && (
          <ConfidenceMenu
            subjectType="Organisation"
            styles={{ marginRight: "-5px" }}
            onConfidenceChange={onConfidenceChange}
            currentSetConfidence={currentSetConfidence}
            originalSetConfidence={originalSetConfidence}
            confidenceHasBeenSetOnRegen={
              userAssessment !== AssessmentOptions.NoUserAssessment
            }
            itemIds={userAssessmentIds}
            enabled
          />
        )}
      </S.TopContent>
      <S.MidContent>
        <S.Column>
          <div>
            <S.DataKey>Organisation type</S.DataKey>
            {organisationType ? (
              <S.OrganisationType>
                <TruncateLength>{organisationType}</TruncateLength>
              </S.OrganisationType>
            ) : (
              <S.NoneIdentified>None identified</S.NoneIdentified>
            )}
          </div>
          <div>
            <S.DataKey>Date of incorporation</S.DataKey>
            {dateOfIncorporation?.year ? (
              <div>{formatDate(dateOfIncorporation)}</div>
            ) : (
              <S.NoneIdentified>None identified</S.NoneIdentified>
            )}
          </div>
        </S.Column>
        <S.Column>
          <div>
            <S.DataKey>Parent organisations</S.DataKey>
            {parents?.length > 0 ? (
              <S.ParentName>
                <TruncateLength>{parents[0]?.name}</TruncateLength>{" "}
                <S.ParentNamesAdditionalCount>
                  {parents?.length > 1 ? ` +${parents.length - 1}` : ""}
                </S.ParentNamesAdditionalCount>
              </S.ParentName>
            ) : (
              <S.NoneIdentified>None identified</S.NoneIdentified>
            )}
          </div>
          <div>
            <S.DataKey>Subsidiaries</S.DataKey>
            <div>{subsidiaryIds?.length ?? 0}</div>
          </div>
        </S.Column>
      </S.MidContent>
      <S.OrgStatus>
        {renderOrgStatus()}{" "}
        {riskList?.map(risk => (
          <S.RiskPill key={risk}>
            <TruncateLength>{risk}</TruncateLength>
          </S.RiskPill>
        ))}
      </S.OrgStatus>
    </S.CardContentContainer>
  );

  /* TODO: Wire up new report button */
  return (
    <DeprecatedEntityCard
      customContent={cardContent}
      imageComponent={image}
      isHoverable
      onClick={() => onCardClick({ id: orgId, title: name })}
      onKeyDown={e => {
        if (e.key === "Enter") onCardClick({ id: orgId, title: name });
      }}
      disableNewReportButton
      tabIndex="0"
    />
  );
};

export default SummaryCard;
