import { styled } from "@linaria/react";

import { ReactComponent as SanctionsIconSvg } from "img/icons/sanctions-icon.svg";
import { ReactComponent as WatchlistsIconSvg } from "img/icons/watchlists-icon.svg";
import { ReactComponent as PepsIconSvg } from "img/icons/state-owned.svg";
import { ReactComponent as FlagSvgIconSvg } from "img/icons/flag-icon.svg";
import { ReactComponent as RCAIconSvg } from "img/icons/rca-icon.svg";
import { ReactComponent as SpecialInterestIconSvg } from "img/icons/special-interest-icon.svg";

import { fontSize } from "styles/fonts";
import { grey, standardColors, red } from "styles/colors";

const RiskIcons = styled.div`
  display: flex;
  padding: 1rem 0;

  & > div {
    margin: -4px;
  }
`;

const RiskIcon = styled.div<{ isActive: boolean }>`
  svg {
    path {
      fill: ${({ isActive }) =>
        isActive ? red.directRiskFill : grey.disabled};
    }
  }
`;

const TooltipContent = styled.p`
  margin: 0;
  padding: 0.875rem;
  font-size: ${fontSize.sm};
  max-width: 23.125rem;
`;

const iconStyles = {
  width: "2rem",
  height: "2rem",
  background: standardColors.white,
  "border-radius": "50%"
};

const OtherIcon = styled(FlagSvgIconSvg)`
  ${iconStyles};
`;

const SanctionsIcon = styled(SanctionsIconSvg)`
  ${iconStyles};
`;

const WatchlistsIcon = styled(WatchlistsIconSvg)`
  ${iconStyles};
`;

const PepsIcon = styled(PepsIconSvg)`
  ${iconStyles};
`;

const RCAIcon = styled(RCAIconSvg)`
  ${iconStyles};
`;

const SpecialInterestIcon = styled(SpecialInterestIconSvg)`
  ${iconStyles};
`;

const S = {
  RiskIcons,
  RiskIcon,
  TooltipContent,
  SanctionsIcon,
  WatchlistsIcon,
  PepsIcon,
  SpecialInterestIcon,
  RCAIcon,
  OtherIcon
};

export default S;
