import http from "services/http";
import { apm } from "@elastic/apm-rum";
import endpoints from "services/endpoints";
import {
  EnquiryService,
  models_QueueEnquiryRequest
} from "api/enquiry-report-service";

import { EnquiryRequest, EnquiryResponse } from "./types";

export default class Enquiry {
  inProgress: boolean;

  constructor(inProgress: boolean) {
    this.inProgress = inProgress;
  }

  async create({
    subject,
    contexts,
    activeFeatures,
    projectReference
  }: EnquiryRequest): Promise<EnquiryResponse | null> {
    try {
      const request: models_QueueEnquiryRequest = {
        subject: {
          type: subject.type,
          value: subject.value
        },
        contexts: contexts.map(context => ({
          type: context.type,
          value: context.value ?? undefined // Transform null to undefined
        })),
        activeFeatures,
        projectReference
      };

      const enquiryIdResponse = await EnquiryService.postV1Enquiry({
        request
      });

      return {
        enquiryId: enquiryIdResponse.enquiryId ?? ""
      };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
    }
    return null;
  }

  status(enquiryId: string) {
    return new Promise((resolve, reject) => {
      this.inProgress = true;
      http
        .post(`${endpoints.enquiryStatus}`, { EnquiryId: enquiryId })
        .then(s => {
          this.inProgress = false;
          resolve(s);
        })
        .catch(e => {
          this.inProgress = false;
          reject(e);
        });
    });
  }
}
