import React from "react";

import Pill from "components/atoms/Pill";

import useEntityList from "util/hooks/useEntityList";
import S from "./styles";

const EntityListControls = () => {
  const {
    state: { filters, activeFilters, details },
    updateActiveFilter
  } = useEntityList();

  const filterableFilters = filters?.filter(({ count }) => count > 0);

  return (
    <S.Container>
      <div>{details?.title}</div>
      <S.Controls>
        Filter by:{" "}
        {filterableFilters?.map(({ label, count, filterBy }) => (
          <Pill
            key={`EntityListControl-${label}`}
            rightAddon={count}
            isActive={activeFilters?.includes(filterBy)}
            onClick={() => updateActiveFilter(filterBy)}
          >
            {label}
          </Pill>
        ))}
      </S.Controls>
    </S.Container>
  );
};

export default EntityListControls;
