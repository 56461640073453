import { createContext } from "react";

import {
  type Entity,
  type EntityList,
  type FilterList,
  type EntityListDetails
} from "api/entities/types";

import {
  type EntityListState,
  type EntityListAction,
  EntityListActions,
  NewReportProps
} from "./types";

export const EntityListContext = createContext({
  state: { entityList: [] } as EntityListState,
  filteredEntityList: [] as EntityList,
  filteredCommissionedEntityList: [] as EntityList,
  updateEntityList: (_entityList: EntityList) => {},
  updateCommissionedEntityList: (_entityList: EntityList) => {},
  updateCommissionedEntity: (_entity: Entity) => {},
  updateFilters: (_filters: FilterList) => {},
  updateDetails: (_details: EntityListDetails) => {},
  updateIsExpanded: (_isExpanded: boolean) => {},
  updateActiveFilter: (_activeFilter: RegExp) => {},
  commissionReport: (_props: NewReportProps) => {}
});

export const entityListReducer = (
  state: EntityListState,
  action: EntityListAction
) => {
  switch (action.type) {
    case EntityListActions.updateActiveFilter: {
      const activeFilters = state.activeFilters?.includes(action.activeFilter)
        ? state.activeFilters.filter(filter => filter !== action.activeFilter)
        : [...(state.activeFilters ?? []), action.activeFilter];

      return {
        ...state,
        activeFilters
      };
    }
    case EntityListActions.updateEntityList: {
      return { ...state, entityList: action.entityList };
    }
    case EntityListActions.updateCommissionedEntityList: {
      return {
        ...state,
        commissionedEntityList: action.commissionedEntityList
      };
    }
    case EntityListActions.updateFilters: {
      return { ...state, filters: action.filters };
    }
    case EntityListActions.updateDetails: {
      return { ...state, details: action.details };
    }
    case EntityListActions.updateIsExpanded: {
      return { ...state, isExpanded: action.isExpanded };
    }
    case EntityListActions.updateCommissionedEntity: {
      const updatedCommissionedEntityList = state.commissionedEntityList?.map(
        entity => (entity.id === action.entity.id ? action.entity : entity)
      );

      return {
        ...state,
        commissionedEntityList: updatedCommissionedEntityList
      };
    }
    default: {
      return state;
    }
  }
};
