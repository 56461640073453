import React, { useState, type FC } from "react";

import SectionHeader from "pages/report/SectionHeader";
import EntityList from "components/molecules/EntityList";
import EntityListHeader from "components/organisms/EntityListHeader";

import S from "./styles";

interface Props {
  title: string;
}

const EntitySection: FC<Props> = ({ title }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const onToggleExpanded = () => setIsExpanded(prev => !prev);

  return (
    <S.Container className="report-section">
      <SectionHeader
        title={title}
        isContractable
        toggleExpanded={onToggleExpanded}
        isExpanded={isExpanded}
      />
      <EntityListHeader />
      <EntityList />
    </S.Container>
  );
};

export default EntitySection;
