import { styled } from "@linaria/react";
import BaseHeading from "components/atoms/Heading";

const Heading = styled(BaseHeading)`
  padding: 16px 16px 0;
`;

const GridContainer = styled.div`
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 16px;
  gap: 16px;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
`;

const S = {
  ListContainer,
  GridContainer,
  Heading
};

export default S;
