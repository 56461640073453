import { styled } from "@linaria/react";
import { grey, green } from "styles/colors";

const Roles = styled.div``;

const CurrentRole = styled.div``;

const OtherRoles = styled.div`
  color: ${grey.mid};
  text-transform: capitalize;
`;

const RoleItemList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const RoleItem = styled.div`
  border: 1px solid ${green.xapienGreen};
  border-radius: 10px;
  padding: 2px 12px;
`;

const S = {
  RoleItemList,
  RoleItem,
  CurrentRole,
  OtherRoles,
  Roles
};

export default S;
