import { styled } from "@linaria/react";

import { grey } from "styles/colors";
import { fontSize } from "styles/fonts";

const Container = styled.div`
  padding: 16px;
  color: ${grey.dark};
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: ${fontSize.sm};
`;

const Controls = styled.div`
  display: flex;
  gap: 8px;
`;

const S = {
  Container,
  Controls
};

export default S;
