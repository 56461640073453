import React, { type FC } from "react";

import { InfoListType, type InfoList } from "api/entities";

import EntityRoles from "components/molecules/EntityRoles";
import EntityLocations from "components/molecules/EntityLocations";
import EntityTags from "components/molecules/EntityTags";
import EntityDates from "components/molecules/EntityDates";
import { type InformationSource } from "api/report/report-types";

type Props = InfoList & { sources: InformationSource[] };

const EntityInfoList: FC<Props> = ({ type, items, sources }) => {
  switch (type) {
    case InfoListType.locations: {
      return <EntityLocations items={items} sources={sources} />;
    }
    case InfoListType.tags: {
      return <EntityTags items={items} />;
    }
    case InfoListType.roles: {
      return <EntityRoles items={items} sources={sources} />;
    }
    case InfoListType.dates: {
      return <EntityDates items={items} sources={sources} />;
    }

    default: {
      return null;
    }
  }
};

export default EntityInfoList;
