import React, { useReducer, useCallback, useMemo, type ReactNode } from "react";

import EntitiesApi from "api/entities";
import { useEnquiryId } from "util/hooks/useEnquiryId";

import {
  type Entity,
  type EntityList,
  type FilterList,
  type EntityListDetails,
  InfoListType
} from "api/entities/types";

import {
  EntityListActions,
  type EntityListAction,
  type NewReportProps
} from "./types";

import { EntityListContext, entityListReducer } from "./context";

const getFilteredEntityList = (
  entityList?: EntityList,
  activeFilters: RegExp[] | undefined = []
): EntityList => {
  if (!entityList || entityList.length === 0) return [];
  if (!activeFilters || activeFilters.length === 0) return entityList;

  return entityList.filter(entity =>
    entity.lists.some(
      list =>
        list.type === InfoListType.tags &&
        list.items.some(item =>
          activeFilters.some(regex => regex.test(item.value))
        )
    )
  );
};

export const EntityListContextProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const enquiryId = useEnquiryId();
  const [state, entityListDispatch] = useReducer(entityListReducer, {});

  const dispatch = useCallback((action: EntityListAction) => {
    entityListDispatch(action);
  }, []);

  const updateEntityList = useCallback(
    (entityList: EntityList) =>
      dispatch({ type: EntityListActions.updateEntityList, entityList }),
    [dispatch]
  );

  const updateCommissionedEntityList = useCallback(
    (commissionedEntityList: EntityList) =>
      dispatch({
        type: EntityListActions.updateCommissionedEntityList,
        commissionedEntityList
      }),
    [dispatch]
  );

  const updateCommissionedEntity = useCallback(
    (entity: Entity) =>
      dispatch({
        type: EntityListActions.updateCommissionedEntity,
        entity
      }),
    [dispatch]
  );

  const updateFilters = useCallback(
    (filters: FilterList) =>
      dispatch({ type: EntityListActions.updateFilters, filters }),
    [dispatch]
  );

  const updateDetails = useCallback(
    (details: EntityListDetails) =>
      dispatch({ type: EntityListActions.updateDetails, details }),
    [dispatch]
  );

  const updateIsExpanded = useCallback(
    (isExpanded: boolean) =>
      dispatch({ type: EntityListActions.updateIsExpanded, isExpanded }),
    [dispatch]
  );

  const updateActiveFilter = useCallback(
    (activeFilter: RegExp) =>
      dispatch({ type: EntityListActions.updateActiveFilter, activeFilter }),
    [dispatch]
  );

  const filteredEntityList = useMemo(
    () => getFilteredEntityList(state.entityList, state.activeFilters),
    [state.entityList, state.activeFilters]
  );

  const filteredCommissionedEntityList = useMemo(
    () =>
      getFilteredEntityList(state.commissionedEntityList, state.activeFilters),
    [state.commissionedEntityList, state.activeFilters]
  );

  const commissionReport = useCallback(
    ({
      label: relatedPartyId,
      successCallback,
      failureCallback
    }: NewReportProps) => {
      const api = new EntitiesApi();

      api
        .createCommissionedParty({ enquiryId, relatedPartyId })
        .then(({ response }) => {
          if (response) {
            successCallback();
            updateCommissionedEntityList([
              ...(state?.commissionedEntityList ?? []),
              response
            ]);
            updateEntityList(
              state?.entityList?.filter(({ id }) => id !== relatedPartyId) ?? []
            );
          } else {
            failureCallback();
          }
        })
        .catch(() => {
          failureCallback();
        });
    },
    [
      state.commissionedEntityList,
      state.entityList,
      enquiryId,
      updateCommissionedEntityList,
      updateEntityList
    ]
  );

  const providerValue = useMemo(
    () => ({
      state,
      filteredEntityList,
      filteredCommissionedEntityList,
      updateEntityList,
      updateCommissionedEntityList,
      updateCommissionedEntity,
      updateFilters,
      updateDetails,
      updateIsExpanded,
      updateActiveFilter,
      commissionReport
    }),
    [
      state,
      filteredEntityList,
      filteredCommissionedEntityList,
      updateEntityList,
      updateCommissionedEntityList,
      updateCommissionedEntity,
      updateFilters,
      updateDetails,
      updateIsExpanded,
      updateActiveFilter,
      commissionReport
    ]
  );

  return (
    <EntityListContext.Provider value={providerValue}>
      {children}
    </EntityListContext.Provider>
  );
};
