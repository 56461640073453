import React, { type FC } from "react";

import { type DateItem } from "api/entities";
import { type InformationSource } from "api/report/report-types";

import WithInspector from "components/organisms/WithInspector";

import S from "./styles";

interface Props {
  items: DateItem[];
  sources: InformationSource[];
}

const EntityDates: FC<Props> = ({ items, sources }) => {
  if (items.length > 0) {
    const { value } = items[0];

    return (
      <WithInspector sources={sources}>
        <S.Dates>{value}</S.Dates>
      </WithInspector>
    );
  }
  return null;
};

export default EntityDates;
