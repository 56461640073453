import { styled } from "@linaria/react";
import { fontSize } from "styles/fonts";
import { grey, green } from "styles/colors";

const Container = styled.div`
  padding: 0 16px;
`;

const Content = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 16px 0;
  border-top: 1px solid ${grey.rule};
  color: ${grey.dark};
  font-size: ${fontSize.sm};
`;

const Icon = styled.div`
  font-size: ${fontSize.lg};
  color: ${green.xapienGreen};
`;

const S = {
  Container,
  Content,
  Icon
};

export default S;
