import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import EntitiesApi from "api/entities";
import { useEnquiryId } from "util/hooks/useEnquiryId";

import EntitySection from "components/organisms/EntitySection";
import RelatedPartiesEntitySectionHelpText from "components/molecules/RelatedPartiesEntitySectionHelpText";
import useEntityList, {
  EntityListContextProvider
} from "util/hooks/useEntityList";

const RelatedPartiesEntitySection = () => {
  const enquiryId = useEnquiryId();
  const {
    updateEntityList,
    updateCommissionedEntityList,
    updateFilters,
    updateDetails
  } = useEntityList();
  const { search } = useLocation();

  useEffect(() => {
    updateDetails({
      title: "Beneficial owners, directors and senior management.",
      label: "Ultimate Beneficial Owner threshold",
      value: "25%",
      helpText: <RelatedPartiesEntitySectionHelpText />,
      commissionedEntityListTitle: "Related parties research",
      entityListTitle: "Related parties"
    });

    const api = new EntitiesApi();

    const params = new URLSearchParams(search);
    const shareToken = params.get("token");

    api
      .getRelatedParties({ enquiryId, shareToken })
      .then(({ response }) => {
        if (response) {
          updateCommissionedEntityList(response.commissionedEntityList);
          updateEntityList(response.entityList);
          updateFilters(response.entityFilters);
        }
      })
      .catch(() => {});
  }, [
    updateDetails,
    updateEntityList,
    updateCommissionedEntityList,
    updateFilters,
    search,
    enquiryId
  ]);

  return <EntitySection title="Related parties research" />;
};

const RelatedPartiesResearch = () => {
  return (
    <EntityListContextProvider>
      <RelatedPartiesEntitySection />
    </EntityListContextProvider>
  );
};

export default RelatedPartiesResearch;
