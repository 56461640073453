import React from "react";

import S from "./styles";

const EntityListIconLegend = () => {
  return (
    <S.Container>
      <S.Content>
        <S.Icon>★</S.Icon>{" "}
        <span>
          Recommended for new Xapien reports. Click <strong>New report</strong>{" "}
          on any of the related parties below to begin your onward research.
        </span>
      </S.Content>
    </S.Container>
  );
};

export default EntityListIconLegend;
