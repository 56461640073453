import { styled } from "@linaria/react";

import { standardColors } from "styles/colors";

const Container = styled.div`
  background: ${standardColors.white};
  border-radius: 10px;
`;

const S = {
  Container
};

export default S;
