import { styled } from "@linaria/react";
import { fontSize } from "styles/fonts";
import { green } from "styles/colors";

const Icon = styled.div`
  line-height: 1;
  font-size: ${fontSize.lg};
  color: ${green.xapienGreen};
`;

const S = { Icon };

export default S;
