import React from "react";

import Heading from "components/atoms/Heading";

import S from "./styles";

const RelatedPartiesEntitySectionHelpText = () => {
  return (
    <S.Container>
      <Heading level={4}>Related parties research</Heading>
      <p>
        An <strong>Ultimate Beneficial Owner</strong> (UBO) is any entity with a
        share ownership of 25% or greater. These are recommended for new Xapien
        reports (marked with a green star) .
      </p>
      <p>
        A <strong>Shareholder</strong> is any entity with a shareholding of 10%
        or greater.
      </p>
      <p>
        A <strong>Director</strong> is any current or recent registered
        director.
      </p>
    </S.Container>
  );
};

export default RelatedPartiesEntitySectionHelpText;
