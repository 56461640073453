import { styled } from "@linaria/react";
import { grey, blue } from "styles/colors";
import { fontSize, lineHeight } from "styles/fonts";

import { Info } from "react-feather";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: ${grey.dark};
  padding: 16px;
  align-items: flex-end;
  gap: 16px;
`;

const Heading = styled.div`
  font-size: ${fontSize["2xl"]};
  line-height: ${lineHeight.xs};
`;

const Content = styled.div`
  font-size: ${fontSize.xs};
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Link = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${blue.link};
`;

const InfoIcon = styled(Info)`
  width: 14px;
  height: 14px;
`;

const S = {
  Container,
  Heading,
  Content,
  Link,
  InfoIcon
};

export default S;
