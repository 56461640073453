import { useContext } from "react";

import { EntityListContext } from "./context";

export { EntityListContextProvider } from "./provider";

const useEntityList = () => {
  const context = useContext(EntityListContext);

  if (!context) {
    throw new Error(
      "useEntityList must be used within a EntityListContextProvider"
    );
  }

  return context;
};

export default useEntityList;
