import React, { useState, useRef } from "react";

import NewReportButton from "pages/report/NewReportButton";
import businessAssociatePlaceholderImageIcon from "img/icons/profile-placeholder.svg";
import { toTitleCase } from "util/common";
import { ENTITY_TYPE, SUBJECT_CONTEXTS } from "pages/define/utils";
import WithInspector from "components/organisms/WithInspector";
import { standardColors } from "styles/colors";

import theme from "theme";

import S from "./styles";

const DeprecatedEntityCard = ({
  name,
  connectionList = [],
  contextToUse,
  nameToSendToNewReport,
  imageComponent,
  imageSource,
  nameOnlySearch,
  shouldCardBeUniformHeight,
  customContent,
  knownToBeInactive,
  disableInspectorInteraction,
  isHoverable = false,
  minHeight,
  showCardImmediately = true,
  cardBorderColorOverride,
  cardBackgroundColorOverride,
  hideNewReportButton = false,
  disableNewReportButton = false,
  reportSubjectType = SUBJECT_CONTEXTS?.person?.organisation?.id,
  entityType = ENTITY_TYPE?.Person,
  onClick = () => {},
  onKeyDown = () => {},
  onMouseLeave = () => {},
  cardStyles,
  tabIndex,
  className,
  style
}) => {
  const [imageErrored, setImageErrored] = useState(false);
  const [showCard, setShowCard] = useState(showCardImmediately);

  const cardRef = useRef(null);

  const renderName = () => {
    if (typeof name === "string") {
      return name;
    }

    const nameString = name?.name;
    return nameString && nameString === nameString.toUpperCase()
      ? toTitleCase(nameString)
      : nameString;
  };

  const renderImage = () => {
    if (imageComponent) {
      return (
        <S.CardImageSection>
          <div>{imageComponent}</div>
        </S.CardImageSection>
      );
    }

    const imageElement = (
      <S.CardImageSection>
        <S.ImageContainer>
          {imageSource && !imageErrored ? (
            <S.Image
              image={imageSource}
              fallbackImage={businessAssociatePlaceholderImageIcon}
              alt="Entity"
              onError={() => setImageErrored(true)}
              width="74px"
              height="74px"
              lazyLoad={false}
            />
          ) : (
            <S.PlaceholderIcon />
          )}
        </S.ImageContainer>
      </S.CardImageSection>
    );

    if (!imageSource || imageErrored) {
      return imageElement;
    }

    return (
      <WithInspector
        sources={
          imageSource && [
            {
              url: imageSource?.originUrl
            }
          ]
        }
        highlightPadding="6px"
        disabled={disableInspectorInteraction}
      >
        {imageElement}
      </WithInspector>
    );
  };

  return (
    <S.CardContainer
      isKnownToBeInactive={knownToBeInactive}
      borderColor={cardBorderColorOverride ?? theme.primaryColor}
      showCard={showCard}
      style={style}
      className={className}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onMouseLeave={onMouseLeave}
    >
      <S.CardInnerContainer
        tabIndex={tabIndex}
        ref={cardRef}
        shouldCardBeUniformHeight={shouldCardBeUniformHeight}
        backgroundColor={cardBackgroundColorOverride}
        minHeight={minHeight}
        isHoverable={isHoverable}
        onMouseOver={() => {
          if (isHoverable && cardRef.current) {
            cardRef.current.style.backgroundColor = "rgba(2, 163, 213, 0.1)";
          }
        }}
        onMouseOut={() => {
          if (isHoverable && cardRef.current) {
            cardRef.current.style.backgroundColor = standardColors.white;
          }
        }}
        className={cardStyles}
      >
        <S.CardContentContainer>
          {/** id is used for reducing the opacity of the pdx screening entity card when its assigned confidence has changed  */}
          <S.Container id="pdx-confidence-opacity">
            {renderImage()}
            {!hideNewReportButton && (
              <NewReportButton
                subject={nameToSendToNewReport}
                contextList={
                  // The context type to use will always be the same
                  // as the report subject's type e.g. org. or person.
                  connectionList.length
                    ? connectionList.map(connection => ({
                        value: connection.text,
                        type: reportSubjectType
                      }))
                    : [
                        {
                          value: contextToUse,
                          type: reportSubjectType
                        }
                      ]
                }
                name={renderName()}
                image={renderImage()}
                nameOnlySearch={nameOnlySearch}
                subjectType={entityType}
                isDisabled={disableNewReportButton}
              />
            )}
          </S.Container>
          <S.CardContent>
            {React.cloneElement(customContent, {
              onClick: () => setShowCard(true),
              knownToBeInactive
            })}
          </S.CardContent>
        </S.CardContentContainer>
      </S.CardInnerContainer>
    </S.CardContainer>
  );
};

export default DeprecatedEntityCard;
