/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { model_CommissionedRelatedParty } from "../models/model_CommissionedRelatedParty";
import type { model_GetRelatedPartiesSectionResponse } from "../models/model_GetRelatedPartiesSectionResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class EnquiryService {
  /**
   * Gets the related parties for an enquiry
   * Gets the related parties for an enquiry
   * @returns model_GetRelatedPartiesSectionResponse OK
   * @throws ApiError
   */
  public static getV1Enquiry({
    enquiryId
  }: {
    /**
     * Enquiry ID
     */
    enquiryId: string;
  }): CancelablePromise<model_GetRelatedPartiesSectionResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/v1/enquiry/{enquiry_id}",
      path: {
        enquiry_id: enquiryId
      },
      errors: {
        400: `Invalid Enquiry ID`,
        404: `Enquiry not found`,
        500: `Internal server error`
      }
    });
  }

  /**
   * Gets a commissioned related party for an enquiry
   * Gets a commissioned related party for an enquiry
   * @returns model_CommissionedRelatedParty OK
   * @throws ApiError
   */
  public static getV1Enquiry1({
    enquiryId,
    relatedPartyId
  }: {
    /**
     * Enquiry ID
     */
    enquiryId: string;
    /**
     * Related Party ID
     */
    relatedPartyId: string;
  }): CancelablePromise<model_CommissionedRelatedParty> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/v1/enquiry/{enquiry_id}/{related_party_id}",
      path: {
        enquiry_id: enquiryId,
        related_party_id: relatedPartyId
      },
      errors: {
        400: `Invalid Related Party ID`,
        404: `Related Party not found`,
        500: `Internal server error`
      }
    });
  }

  /**
   * Commissions a single related party for an enquiry
   * Commissions a single related party for an enquiry
   * @returns model_CommissionedRelatedParty OK
   * @throws ApiError
   */
  public static putV1EnquiryCommissionResearch({
    enquiryId,
    relatedPartyId
  }: {
    /**
     * Enquiry ID
     */
    enquiryId: string;
    /**
     * Related Party ID
     */
    relatedPartyId: string;
  }): CancelablePromise<model_CommissionedRelatedParty> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/v1/enquiry/{enquiry_id}/{related_party_id}/commission_research",
      path: {
        enquiry_id: enquiryId,
        related_party_id: relatedPartyId
      },
      errors: {
        400: `Invalid Related Party ID`,
        404: `Related Party not found`,
        500: `Internal server error`
      }
    });
  }
}
