import React, { type FC } from "react";

import { ChipVariant, ChipSize } from "components/atoms/Chip/types";
import Chip from "components/atoms/Chip";

import { type TagItem } from "api/entities";

import S from "./styles";

interface Props {
  items: TagItem[];
}

const EntityTags: FC<Props> = ({ items }) => {
  return (
    <S.Tags>
      {items.map(({ id, value }) => (
        <Chip
          key={`EntityTagChip-${id}`}
          label={value}
          size={ChipSize.Medium}
          variant={ChipVariant.FilledMid}
        />
      ))}
    </S.Tags>
  );
};

export default EntityTags;
